import React, { useContext } from "react"

import { RippleAnimate } from "components/common/SVGIcons/svg"
import { CURSOR_TYPE_CONST, MouseContext } from "components/MouseContext"
import LogoMAPAnimate from "components/common/LogoMAPAnimate"

import LoadingStyles from "./styles"
import LoadingBG from "./LoadingBG"

const Loading = ({ setLoading }) => {
  const { cursorChangeHandler } = useContext(MouseContext);

  const handleClickLogo = (e) => {
    setTimeout(() => {
      setLoading(false);
      cursorChangeHandler(CURSOR_TYPE_CONST.default)
    }, 600)
  };


  return (
    <LoadingStyles>
      <div
        id="audio-start"
        role="presentation"
        onClick={handleClickLogo}
        className="logo-loading-group animation-target"
        onMouseEnter={() => cursorChangeHandler(CURSOR_TYPE_CONST.hovered)}
        onMouseLeave={() => cursorChangeHandler(CURSOR_TYPE_CONST.clicked)}
      >
        <div className="ripple">{RippleAnimate}</div>

        <LogoMAPAnimate />

        <div className="logo-loading-desc">the WHITE DISTANCE</div>
      </div>

      {/* <div className="logo-text"> */}
      {/* <svg
          width="120"
          height="100"
          viewBox="0 0 120 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="triangles">
            <g id="lightGroup">
              <path
                id="light1"
                opacity="0.6"
                d="M53.4872 46.3509C55.7436 47.6536 55.7436 50.9105 53.4872 52.2132L13.718 75.174C11.4615 76.4767 8.64104 74.8483 8.64104 72.2428L8.64104 26.3213C8.64104 23.7158 11.4616 22.0874 13.718 23.3901L53.4872 46.3509Z"
              />
            </g>
            <g id="darkGroup">
              <path
                id="dark1"
                opacity="0.8"
                d="M74.9231 46.915C77.1795 48.2177 77.1795 51.4746 74.9231 52.7773L34.3077 76.2266C32.0513 77.5294 29.2308 75.9009 29.2308 73.2955L29.2308 26.3968C29.2308 23.7914 32.0513 22.1629 34.3077 23.4657L74.9231 46.915Z"
              />
              <path
                id="dark2"
                opacity="0.8"
                d="M54.6154 46.915C56.8718 48.2177 56.8718 51.4746 54.6154 52.7773L14 76.2266C11.7436 77.5294 8.92307 75.9009 8.92307 73.2955L8.92308 26.3968C8.92308 23.7914 11.7436 22.1629 14 23.4657L54.6154 46.915Z"
              />
            </g>
          </g>
        </svg> */}

      <LoadingBG />
    </LoadingStyles>
  )
}

Loading.propTypes = {}

export default Loading
