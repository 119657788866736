import React from "react"
// import PropTypes from "prop-types"
import useMousePosition from "hooks/useMousePosition"
import useInnerWindow from "hooks/useInnerWindow";

const LoadingBG = () => {
  const { x, y } = useMousePosition()
  const { innerWidth, innerHeight } = useInnerWindow();
  const barW = innerWidth * 0.2;
  const barH = innerHeight * 0.2;
  const extraW = innerWidth - barW;
  const extraH = innerHeight - barH;
  
  return (
    <div className="background-loading">
      <svg
        width="100vw"
        height="100vh"
        // viewBox="0 0 1440 900"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="BG">
          <rect
            id="MainRight"
            opacity="0.6"
            x={x || extraW}
            width={barW}
            height="100vh"
            fill="url(#paint0_linear_1:3)"
            // fill="blue"
          />
          <rect
            id="MainLeft"
            opacity="0.8"
            width="100vw"
            height="100vh"
            fill="url(#paint1_linear_1:3)"
          />
          <g id="OverlayTop" style={{ mixBlendMode: "screen" }}>
            <rect
              width="100vw"
              height="100vh"
              // transform="matrix(1 0 -0.000518307 1 0.922119 0)"
              fill="url(#paint2_linear_1:3)"
            />
          </g>
          <g id="OverlayBottom" style={{ mixBlendMode: "screen" }}>
            <rect
              width={"100vw"}
              height={barW}
              y={y || extraH}
              // transform="matrix(1 0 0 1 0 )"
              fill="url(#paint3_linear_1:3)"
              // fill="red"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1:3"
            x1={x || extraW}
            y1="50%"
            x2={(x || extraW) + barW}
            y2="50%"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C6C6C6" stopOpacity={0} />
            <stop offset="0.15625" stopColor="#616161" />
            <stop offset="0.359375" stopColor="#D3D3D3" />
            <stop offset="0.395833" stopColor="#777777" />
            <stop offset="0.463542" stopColor="#A4A4A4" />
            <stop offset="0.614583" stopColor="#8A8A8A" />
            <stop offset="0.739583" stopColor="#BDBDBD" />
            <stop offset="0.765625" stopColor="#CACACA" />
            <stop offset="0.880208" stopColor="#8A8A8A" />
            <stop offset={1} stopColor="#CFCFCF" stopOpacity={0} />
            {/* <stop stopColor="yellow" stopOpacity={0} />
            <stop offset="0.15625" stopColor="green" />
            <stop offset="0.359375" stopColor="purple" />
            <stop offset="0.395833" stopColor="orange" />
            <stop offset="0.463542" stopColor="pink" />
            <stop offset="0.614583" stopColor="black" />
            <stop offset="0.739583" stopColor="blue" />
            <stop offset="0.765625" stopColor="red" />
            <stop offset="0.880208" stopColor="white" /> */}
            <stop offset={1} stopColor="cyan" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1:3"
            x1="2.68221e-06"
            y1="50%"
            x2="100%"
            y2="50%"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C6C6C6" stopOpacity={0} />
            <stop offset="0.15625" stopColor="#616161" />
            <stop offset="0.359375" stopColor="#D3D3D3" />
            <stop offset="0.395833" stopColor="#777777" />
            <stop offset="0.463542" stopColor="#A4A4A4" />
            <stop offset="0.614583" stopColor="#8A8A8A" />
            <stop offset="0.739583" stopColor="#BDBDBD" />
            <stop offset="0.765625" stopColor="#CACACA" />
            <stop offset="0.880208" stopColor="#8A8A8A" />
            <stop offset={1} stopColor="#CFCFCF" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1:3"
            x1="70%"
            y1={extraH}
            x2="70%"
            y2="8.81046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.1" />
            <stop offset="0.5" stopColor="#949494" />
            <stop offset={1} stopColor="#383838" />
          </linearGradient>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="paint3_linear_1:3"
            x1={0}
            x2={0}
            y1={y || extraH}
            y2={(y || extraH) + barH}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.1" />
            <stop offset="0.33" stopColor="#383838" />
            <stop offset="0.7" stopColor="#949494" />
            {/* <stop offset="0.3" stopColor="red" />
            <stop offset="0.7" stopColor="blue" /> */}
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

LoadingBG.propTypes = {}

export default LoadingBG
