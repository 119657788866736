import React, { useContext, useState, useRef } from "react"
// import PropTypes from 'prop-types';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps"

import geoUrl from "data/world-map.json"
import "react-minimap/dist/react-minimap.css"
import projectBall from "images/project-ball.png"

import useInnerWindow from "hooks/useInnerWindow"
import { DEFAULT_HEIGHT_MAP, DEFAULT_WIDTH_MAP } from "configs/constants"
import { ARTWORKS_MAP } from "data/artworks"

import { CURSOR_TYPE_CONST, MouseContext } from "components/MouseContext"
import ArtistDetails from "containers/ArtistDetails"

import StylesWorldMap from "./styles"

const getShortProjectName = str =>
  `${str?.substring(0, 18)}${str?.length > 18 ? "..." : ""}`

const WorldMap = () => {
  // const [zoom, setZoom] = useState(1.5);
  const { innerWidth, innerHeight } = useInnerWindow()

  const widthMap = DEFAULT_WIDTH_MAP > innerWidth ? DEFAULT_WIDTH_MAP : innerWidth
  const heightMap =
    DEFAULT_HEIGHT_MAP > innerHeight ? DEFAULT_HEIGHT_MAP : innerHeight

  const artistDetailRef = useRef()

  const openProjectDetail = id => {
    artistDetailRef.current.open(id)
  }

  const { cursorType, cursorChangeHandler } = useContext(MouseContext)

  const [idxCursor, setIdxCursor] = useState()

  const checkIsHover = i => {
    return cursorType === CURSOR_TYPE_CONST.hovered && idxCursor === i
  }

  return (
    <StylesWorldMap>
      <ComposableMap
        data-tip=""
        projectionConfig={{
          rotate: [0, 0, 0],
          scale: (widthMap / heightMap) * 118,
        }}
      >
        {/* <ZoomableGroup
          minZoom={2}
          zoom={2}
          maxZoom={2}
          disableZooming
          disablePanning
          center={[-530, 100]}
        > */}
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies &&
            geographies.map((geo, i) => (
              <Geography
                key={String(i)}
                geography={geo}
                stroke="#E4E4E4"
                strokeWidth={0.8}
                strokeDasharray="2,2"
                strokeLinecap="round"
                outline="none"
                style={{
                  default: {
                    fill: "#fff",
                    outline: "none",
                  },
                  hover: {
                    fill: "#fff",
                    outline: "none",
                    // cursor: "pointer",
                  },
                  pressed: {
                    fill: "#cdd6f5",
                    outline: "none",
                    cursor: "pointer",
                  },
                }}
                // onMouseEnter={() => {
                //   const { NAME, POP_EST } = geo.properties;
                //   setContent(`${NAME} — ${rounded(POP_EST)}`);
                // }}
                // onMouseLeave={() => {
                //   setContent('');
                // }}
              />
            ))
          }
        </Geographies>
        {ARTWORKS_MAP?.map(
          (
            { projectName, displayName, coordinates, markerOffsetX, markerOffsetY, id },
            i
          ) => {
            const isHover = checkIsHover(i)
            return (
              <Marker
                key={String(i)}
                coordinates={coordinates}
                onClick={() => openProjectDetail(id)}
                onMouseEnter={() => {
                  cursorChangeHandler(CURSOR_TYPE_CONST.hovered)
                  setIdxCursor(i)
                }}
                onMouseLeave={() => {
                  cursorChangeHandler(CURSOR_TYPE_CONST.default)
                  setIdxCursor(i)
                }}
                className={`${isHover ? "market-hovered" : "market-item"}`}
              >
                <svg
                  className="market-item__ball"
                  x={0}
                  y={-50}
                  viewBox="0 0 100 100"
                  width="100"
                  height="100"
                >
                  <image
                    className="market-item__ball-image animation-target"
                    width="100"
                    height="100"
                    xlinkHref={projectBall}
                  />
                </svg>
                <circle
                  className="market-item__circle"
                  r={4}
                  fill={"rgba(0, 0, 0, 0.3)"}
                  style={{
                    cursor: "pointer",
                    hover: {
                      "stroke-width": 1,
                      stroke: "rgba(0,0,0,0.3)",
                    },
                  }}
                />
                <text
                  textAnchor="left"
                  style={{ fill: "rgba(0,0,0,0.3)", userSelect: "none", fontSize: 9 }}
                  y={markerOffsetY || 3}
                  x={markerOffsetX || 8}
                  className="market-item__name animation-target"
                >
                  {getShortProjectName(displayName?.en || projectName)}
                </text>
              </Marker>
            )
          }
        )}
        {/* </ZoomableGroup> */}
      </ComposableMap>

      <ArtistDetails ref={artistDetailRef} />
    </StylesWorldMap>
  )
}

export default WorldMap
