import styled from "styled-components"

export const DotRingStyles = styled.div`
  /* Cursor-----------*/
  .cursor {
    width: 40px;
    height: 40px;
    border: 2px solid #fefefe;
    border-radius: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all 150ms ease;
    transition-property: background-color, opacity, transform, mix-blend-mode;
    z-index: 9999;
    mix-blend-mode: difference;

    &:after {
      content: "";
      width: 14px;
      height: 14px;
      background: #000;
      position: absolute;
    }
  }

  .cursor--hidden {
    opacity: 0;
  }

  .cursor--link-hovered {
    transform: translate(-50%, -50%) scale(1.25);
    background-color: #fefefe;
  }

  .cursor--clicked {
    transform: translate(-50%, -50%) scale(0.9);
    background-color: #fefefe;
  }

  .ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    border: 2px solid rgba(0, 0, 0);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s;
    /* -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; */
    /* will-change: width, height, transform, border; */
    z-index: 999;
    pointer-events: none;
    opacity: 1;
  }

  .dot {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
    transition: opacity 0.2s ease;
    opacity: 1;
  }

  .ring.hovered {
    border-color: #ffffff;
    width: 50px;
    height: 50px;
    opacity: 1;
  }
  .dot.hovered {
    background: #ffffff;
    width: 26px;
    height: 26px;
    opacity: 1;
  }

  @keyframes ringMouseUp {
    0% {
      opacity: 1;
    }
    80% {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes dotMouseUp {
    0% {
      opacity: 1;
    }
    80% {
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`
