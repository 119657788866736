import { DEFAULT_HEIGHT_MAP, DEFAULT_WIDTH_MAP } from "configs/constants"
import styled from "styled-components"

const StylesWorldMap = styled.div`
  background: white;

  .rsm-svg {
    height: ${DEFAULT_HEIGHT_MAP}px;
    width: ${DEFAULT_WIDTH_MAP}px;
    min-width: 100vw;
    min-height: 100vh;
  }

  .market-item__ball-image {
    transition: all .2s ease-in-out;
    transform: scale(0);
  }

  .market-hovered {
    .market-item__circle {
      display: none;
    }
    .market-item__name {
      fill: #fff !important;
    }
    .market-item__ball-image {
      transform: scale(1) !important;
    }
  }
`
export default StylesWorldMap
