import { useState, useEffect } from "react"

export default function useInnerWindow() {

  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
  }, [])

  // const innerWidth = typeof window !== "undefined" ? window.innerWidth : 0

  // const innerHeight = typeof window !== "undefined" ? window.innerHeight : 0

  return { innerWidth, innerHeight }
}
