import styled from "styled-components"
import loadingBG from "images/loading-background.png"

const LoadingStyles = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* background-image: url(${loadingBG}); */

  /* .ripple,
  .logo-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } */

  /* style Logo text */
  .ripple {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .logo-loading {
    &-group {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &-desc {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      /* text-transform: uppercase; */
      color: rgba(255, 255, 255, 0.4);
      margin-top: 20px;
    }
  }
`

export default LoadingStyles
