import React, { useState, useEffect, useRef } from "react"
import Minimap, { Child as ChildComponent } from "react-minimap"
import miniMapBG from "images/mini-map.png"

import Loading from "components/Loading"
import DotRing from "components/DotRing"
import LogoMAPAnimate from "components/common/LogoMAPAnimate"

import WorldMap from "./WorldMap"
import HomeStyles from "./styles"
import Goo from "gooey-react"

const selectorClass = "rsm-geographies"

const Home = () => {
  // const eyeRef = useRef();
  // const { cursorChangeHandler } = useContext(MouseContext)

  // useEffect(() => {
  //   let eyeBall = document.querySelector(".eyeball"),
  //     pupil = document.querySelector(".pupil"),
  //     eyeArea = eyeBall.getBoundingClientRect(),
  //     pupilArea = pupil.getBoundingClientRect(),
  //     R = eyeArea.width / 2,
  //     r = pupilArea.width / 2,
  //     centerX = eyeArea.left + R,
  //     centerY = eyeArea.top + R

  //   document.addEventListener("mousemove", e => {
  //     let x = e.clientX - centerX,
  //       y = e.clientY - centerY,
  //       theta = Math.atan2(y, x),
  //       angle = (theta * 180) / Math.PI + 360

  //     pupil.style.transform = `translateX(${R - r + "px"}) rotate(${
  //       angle + "deg"
  //     })`
  //     pupil.style.transformOrigin = `${r + "px"} center`
  //   })
  // }, [])

  const ballAnimatedRef = useRef()

  useEffect(() => {
    document.addEventListener("mousemove", function (event) {
      if (ballAnimatedRef.current) {
        const bounding = ballAnimatedRef.current.getBoundingClientRect()

        var x = bounding.x + bounding.width / 2
        var y = bounding.y + bounding.height / 2
        var rad = Math.atan2(event.pageX - x, event.pageY - y)
        var rot = rad * (180 / Math.PI) * -1 + 270

        ballAnimatedRef.current.style.transform = "rotate(" + rot + "deg)"
      }
    })
  }, [])

  const [loading, setLoading] = useState(true)
  const renderChild = ({ width, height, left, top, node }) => {
    let classNameFound = null

    node.classList.forEach(className => {
      if (selectorClass === className) {
        classNameFound = className
        return false
      }
    })

    if (classNameFound === null)
      return (
        <ChildComponent width={width} height={height} left={left} top={top} />
      )

    return (
      <img
        alt="heritage-space"
        style={{
          position: "absolute",
          width,
          height,
          left,
          top,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        src={miniMapBG}
      />
    )
  }

  return (
    <HomeStyles>
      <DotRing />
      {loading ? (
        <Loading setLoading={setLoading} />
      ) : (
        <Minimap
          selector={`.${selectorClass}`}
          keepAspectRatio
          childComponent={renderChild}
        >
          <div className="map-wrapper">
            <WorldMap />
            <div
              id="projectBalls"
              className="project-balls"
              // onMouseEnter={() => cursorChangeHandler(CURSOR_TYPE_CONST.hovered)}
              // onMouseLeave={() => cursorChangeHandler(CURSOR_TYPE_CONST.default)}
            >
              {/* <div style={{ padding: 50, background: "#000" }}>
          <svg width="100" height="100" class="ball-map">
            <circle cx="50" cy="50" r="50" fill="white" class="eyeball" />
            <circle cx="50" cy="50" r="30" fill="#0D0D20" class="pupil" />
          </svg>
        </div> */}

              <LogoMAPAnimate />
              <Goo
                style={{
                  position: "relative",
                }}
              >
                <svg width={440} height={440} viewBox="110 20 320 500">
                  <defs>
                    <linearGradient
                      id="gradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop offset="0%" style={{ stopColor: "#f0f0f0" }} />
                      <stop offset="20%" style={{ stopColor: "#f0f0f0" }} />
                      <stop offset="40%" style={{ stopColor: "#d9d9d9" }} />
                      <stop offset="100%" style={{ stopColor: "#bfbfbf" }} />
                    </linearGradient>
                    <linearGradient
                      id="gradient-ball"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" style={{ stopColor: "#f0f0f0" }} />
                      <stop offset="20%" style={{ stopColor: "#f0f0f0" }} />
                      <stop offset="40%" style={{ stopColor: "#d9d9d9" }} />
                      <stop offset="100%" style={{ stopColor: "#d9d9d9" }} />
                    </linearGradient>
                  </defs>

                  <g
                    ref={ballAnimatedRef}
                    id="ballAnimated"
                    className="dot-animated"
                  >
                    <circle
                      fill="none"
                      stroke="#000"
                      cx="290"
                      cy="270"
                      r="220"
                      style={{
                        mixBlendMode: "multiply",
                      }}
                    />
                    <circle
                      fill="url(#gradient-ball)"
                      cx="70"
                      cy="270"
                      r="50"
                    />
                  </g>

                  <path fill="url(#gradient)">
                    <animate
                      attributeName="d"
                      dur="5s"
                      repeatCount="indefinite"
                      values="
           M476,299Q454,348,426.5,392.5Q399,437,347.5,444Q296,451,246.5,467.5Q197,484,147,463Q97,442,82.5,390Q68,338,55.5,294Q43,250,44.5,201Q46,152,85.5,122.5Q125,93,162.5,61.5Q200,30,248,39Q296,48,340.5,64.5Q385,81,423,114.5Q461,148,479.5,199Q498,250,476,299Z; 

           M453.5,289Q413,328,399.5,374Q386,420,331.5,393.5Q277,367,239,415.5Q201,464,152.5,449Q104,434,129,365Q154,296,141,273Q128,250,108,211Q88,172,137,171Q186,170,198.5,124.5Q211,79,244,104.5Q277,130,310,131.5Q343,133,388.5,147.5Q434,162,464,206Q494,250,453.5,289Z; 


           M476,299Q454,348,426.5,392.5Q399,437,347.5,444Q296,451,246.5,467.5Q197,484,147,463Q97,442,82.5,390Q68,338,55.5,294Q43,250,44.5,201Q46,152,85.5,122.5Q125,93,162.5,61.5Q200,30,248,39Q296,48,340.5,64.5Q385,81,423,114.5Q461,148,479.5,199Q498,250,476,299Z"
                    />
                  </path>
                </svg>
              </Goo>
            </div>
          </div>
        </Minimap>
      )}
    </HomeStyles>
  )
}

Home.propTypes = {}

export default Home
