import styled from "styled-components"

const HomeStyles = styled.div`
  /* cursor: none; */
  height: 100vh;
  width: 100vw;
  background: white;

  /* Start Style Minimap */

  .minimap-container-scroll,
  .minimap-container {
    scroll-behavior: unset !important;
    overflow: auto !important;
  }

  .minimap-viewport {
    border: 1px solid #969696;
    border-radius: 8px;
    background: transparent !important;
  }

  .minimap {
    bottom: 20px;
    left: 20px;
    background: transparent !important;
    border: none !important;
  }

  /* End Style Minimap */

  #projectBalls {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Fix scroll not working when cursor on "element position fixed" */
    .logoMAP {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .ball-map {
    margin: auto;
    /*   background:#ccc; */
  }
  .pupil {
    position: relative;
    /*   transform:  rotate(390deg); */
    /*   transform-origin: 20px center; */
  }

  .ripple-ball g {
    display: contents;
  }

  .eye-wrapper {
    z-index: 12;
    height: 30px;
    width: 30px;
    position: relative;
    display: inline-block;
  }

  .eye {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: #ccc;
  }
  .eye:after {
    /*pupil*/
    position: absolute;
    bottom: 17px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    content: " ";
  }

  @-webkit-keyframes blob_one {
    to {
      transform: rotate(-1turn) translate(-12.5%, -12.5%);
    }
  }
  @keyframes blob_one {
    from {
      transform: translate(0, -5%);
    }
    to {
      transform: translate(0, -20%);
    }
  }
  @-webkit-keyframes blob_two {
    to {
      transform: rotate(-1turn) translate(12.5%, -12.5%);
    }
  }
  @keyframes blob_two {
    from {
      transform: translate(30%, -15%);
    }
    to {
      transform: translate(-30%, -15%);
    }
  }
  @-webkit-keyframes blob_three {
    to {
      transform: rotate(-1turn) translate(-12.5%, 12.5%);
    }
  }
  @keyframes blob_three {
    from {
      transform: translate(-5%, 5%);
    }
    to {
      transform: translate(-15%, 15%);
    }
  }
  @-webkit-keyframes blob_four {
    to {
      transform: rotate(1turn) translate(-12.5%, -12.5%);
    }
  }
  @keyframes blob_four {
    from {
      transform: translate(5%, 5%);
    }
    to {
      transform: translate(12%, 12%);
    }
  }
  @-webkit-keyframes blob_five {
    to {
      transform: rotate(-1turn) translate(12.5%, -12.5%);
    }
  }
  @keyframes blob_five {
    /* from {
      transform: translate(-10%, 10%);
    } */
    to {
      transform: rotate(1turn) translate(-10%, -10%);
    }
  }

  @keyframes blob_six {
    from {
      transform: translate(-30%, -15%);
    }
    to {
      transform: translate(30%, -15%);
    }
  }

  /* SVG BLOBBING EFFECT */
  .blobs {
    filter: url("#goo");
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .blob {
    background-color: #d9d9d9;
    border-radius: 50%;
    color: white;
    line-height: 3em;
    width: 3em;
    text-align: center;
    font-size: 2.5em;
    position: absolute;
    left: 0.5em;
  }
  .blob:nth-child(1) {
    z-index: 3;
  }
  .blob:nth-child(2) {
    z-index: 2;
    animation-name: move2;
  }
  .blob:nth-child(3) {
    z-index: 1;
    animation-name: move3;
  }
  .blob:nth-child(2),
  .blob:nth-child(3) {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes move2 {
    50%,
    100% {
      left: 3.75em;
    }
  }

  @keyframes move3 {
    50% {
      left: 3.75em;
    }
    90%,
    100% {
      left: 7.5em;
    }
  }

  /* SVG Ball DOT */
  .dot-animated {
    /* animation: mercury-group 18s linear infinite; */
    position: relative;
    transform-box: fill-box;
    transform-origin: center;
  }

  @keyframes mercury-group {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(-360deg);
    }
  }
`

export default HomeStyles

// export const DotRingStyles = styled.div`

// `
