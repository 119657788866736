import React from "react"
// import PropTypes from "prop-types"
import LogoMAPStyles from "./styles"

const LogoMAPAnimate = ({ className }) => {
  return (
    <LogoMAPStyles className={`${className} logoMAP`}>
      <svg
        className="logoMAP"
        width={300}
        height={101}
        viewBox="0 0 240 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Logo">
          <g id="MBase" opacity="0.8" style={{ mixBlendMode: "screen" }}>
            <path
              id="MPath1"
              d="M64.656 1.152C65.04 0.384003 65.712 0 66.672 0L86.976 0C87.456 0 87.84 0.191999 88.128 0.576C88.512 0.864002 88.704 1.248 88.704 1.728L88.704 99.072C88.704 99.552 88.512 99.984 88.128 100.368C87.84 100.656 87.456 100.8 86.976 100.8L66.528 100.8C66.048 100.8 65.616 100.656 65.232 100.368C64.944 99.984 64.8 99.552 64.8 99.072L64.8 41.184C64.8 40.8 64.704 40.608 64.512 40.608C64.32 40.608 64.128 40.752 63.936 41.04L51.552 60.912C51.168 61.68 50.496 62.064 49.536 62.064L39.312 62.064C38.352 62.064 37.68 61.68 37.296 60.912L24.768 40.896C24.576 40.608 24.384 40.464 24.192 40.464C24 40.464 23.904 40.656 23.904 41.04L23.904 99.072C23.904 99.552 23.712 99.984 23.328 100.368C23.04 100.656 22.656 100.8 22.176 100.8L1.728 100.8C1.248 100.8 0.816 100.656 0.432 100.368C0.144 99.984 0 99.552 0 99.072L0 1.728C0 1.248 0.144 0.864002 0.432 0.576C0.816 0.191999 1.248 0 1.728 0L22.032 0C22.896 0 23.568 0.384003 24.048 1.152L44.064 32.544C44.352 33.12 44.64 33.12 44.928 32.544L64.656 1.152Z"
              fill="url(#paint0_radial_1:7)"
            />
            <path
              id="MPath2"
              d="M64.656 1.152C65.04 0.384003 65.712 0 66.672 0L86.976 0C87.456 0 87.84 0.191999 88.128 0.576C88.512 0.864002 88.704 1.248 88.704 1.728L88.704 99.072C88.704 99.552 88.512 99.984 88.128 100.368C87.84 100.656 87.456 100.8 86.976 100.8L66.528 100.8C66.048 100.8 65.616 100.656 65.232 100.368C64.944 99.984 64.8 99.552 64.8 99.072L64.8 41.184C64.8 40.8 64.704 40.608 64.512 40.608C64.32 40.608 64.128 40.752 63.936 41.04L51.552 60.912C51.168 61.68 50.496 62.064 49.536 62.064L39.312 62.064C38.352 62.064 37.68 61.68 37.296 60.912L24.768 40.896C24.576 40.608 24.384 40.464 24.192 40.464C24 40.464 23.904 40.656 23.904 41.04L23.904 99.072C23.904 99.552 23.712 99.984 23.328 100.368C23.04 100.656 22.656 100.8 22.176 100.8L1.728 100.8C1.248 100.8 0.816 100.656 0.432 100.368C0.144 99.984 0 99.552 0 99.072L0 1.728C0 1.248 0.144 0.864002 0.432 0.576C0.816 0.191999 1.248 0 1.728 0L22.032 0C22.896 0 23.568 0.384003 24.048 1.152L44.064 32.544C44.352 33.12 44.64 33.12 44.928 32.544L64.656 1.152Z"
              fill="url(#paint1_radial_1:7)"
              fillOpacity="0.6"
            />
          </g>

          <g id="ABase" opacity="0.6" style={{ mixBlendMode: "screen" }}>
            <path
              id="APath1"
              d="M147.413 100.8C146.357 100.8 145.685 100.32 145.397 99.36L141.653 86.544C141.461 86.16 141.221 85.968 140.933 85.968L108.101 85.968C107.813 85.968 107.573 86.16 107.381 86.544L103.637 99.36C103.349 100.32 102.677 100.8 101.621 100.8L79.5894 100.8C79.0134 100.8 78.5814 100.656 78.2934 100.368C78.0054 99.984 77.9574 99.456 78.1494 98.784L108.965 1.44C109.253 0.48 109.877 0 110.837 0L138.053 0C139.013 0 139.637 0.48 139.925 1.44L170.885 98.784C170.981 98.976 171.029 99.216 171.029 99.504C171.029 100.368 170.501 100.8 169.445 100.8L147.413 100.8ZM113.141 66.672C113.045 67.248 113.237 67.536 113.717 67.536L135.173 67.536C135.749 67.536 135.941 67.248 135.749 66.672L124.805 29.664C124.709 29.28 124.565 29.136 124.373 29.232C124.181 29.232 124.037 29.376 123.941 29.664L113.141 66.672Z"
              fill="url(#paint2_radial_1:7)"
            />
            <path
              id="APath2"
              d="M147.413 100.8C146.357 100.8 145.685 100.32 145.397 99.36L141.653 86.544C141.461 86.16 141.221 85.968 140.933 85.968L108.101 85.968C107.813 85.968 107.573 86.16 107.381 86.544L103.637 99.36C103.349 100.32 102.677 100.8 101.621 100.8L79.5894 100.8C79.0134 100.8 78.5814 100.656 78.2934 100.368C78.0054 99.984 77.9574 99.456 78.1494 98.784L108.965 1.44C109.253 0.48 109.877 0 110.837 0L138.053 0C139.013 0 139.637 0.48 139.925 1.44L170.885 98.784C170.981 98.976 171.029 99.216 171.029 99.504C171.029 100.368 170.501 100.8 169.445 100.8L147.413 100.8ZM113.141 66.672C113.045 67.248 113.237 67.536 113.717 67.536L135.173 67.536C135.749 67.536 135.941 67.248 135.749 66.672L124.805 29.664C124.709 29.28 124.565 29.136 124.373 29.232C124.181 29.232 124.037 29.376 123.941 29.664L113.141 66.672Z"
              fill="url(#paint3_radial_1:7)"
              fillOpacity="0.6"
            />
          </g>

          <g id="PBase" opacity="0.4" style={{ mixBlendMode: "screen" }}>
            <path
              id="PPath1"
              d="M206.228 0C212.756 0 218.516 1.344 223.508 4.032C228.596 6.72 232.484 10.512 235.172 15.408C237.956 20.208 239.348 25.776 239.348 32.112C239.348 38.352 237.908 43.872 235.028 48.672C232.244 53.472 228.26 57.168 223.076 59.76C217.892 62.352 211.892 63.648 205.076 63.648L187.508 63.648C187.028 63.648 186.788 63.888 186.788 64.368L186.788 99.072C186.788 99.552 186.596 99.984 186.212 100.368C185.924 100.656 185.54 100.8 185.06 100.8L164.612 100.8C164.132 100.8 163.7 100.656 163.316 100.368C163.028 99.984 162.884 99.552 162.884 99.072L162.884 1.728C162.884 1.248 163.028 0.864002 163.316 0.576C163.7 0.191999 164.132 0 164.612 0L206.228 0ZM202.34 44.64C206.276 44.64 209.444 43.536 211.844 41.328C214.34 39.12 215.588 36.24 215.588 32.688C215.588 29.04 214.34 26.112 211.844 23.904C209.444 21.6 206.276 20.448 202.34 20.448L187.508 20.448C187.028 20.448 186.788 20.688 186.788 21.168L186.788 43.92C186.788 44.4 187.028 44.64 187.508 44.64L202.34 44.64Z"
              fill="url(#paint4_radial_1:7)"
            />
            <path
              id="PPath2"
              d="M206.228 0C212.756 0 218.516 1.344 223.508 4.032C228.596 6.72 232.484 10.512 235.172 15.408C237.956 20.208 239.348 25.776 239.348 32.112C239.348 38.352 237.908 43.872 235.028 48.672C232.244 53.472 228.26 57.168 223.076 59.76C217.892 62.352 211.892 63.648 205.076 63.648L187.508 63.648C187.028 63.648 186.788 63.888 186.788 64.368L186.788 99.072C186.788 99.552 186.596 99.984 186.212 100.368C185.924 100.656 185.54 100.8 185.06 100.8L164.612 100.8C164.132 100.8 163.7 100.656 163.316 100.368C163.028 99.984 162.884 99.552 162.884 99.072L162.884 1.728C162.884 1.248 163.028 0.864002 163.316 0.576C163.7 0.191999 164.132 0 164.612 0L206.228 0ZM202.34 44.64C206.276 44.64 209.444 43.536 211.844 41.328C214.34 39.12 215.588 36.24 215.588 32.688C215.588 29.04 214.34 26.112 211.844 23.904C209.444 21.6 206.276 20.448 202.34 20.448L187.508 20.448C187.028 20.448 186.788 20.688 186.788 21.168L186.788 43.92C186.788 44.4 187.028 44.64 187.508 44.64L202.34 44.64Z"
              fill="url(#paint5_radial_1:7)"
              fillOpacity="0.6"
            />
          </g>
        </g>

        <defs>
          <radialGradient
            id="paint0_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="50%"
            fy="50%"
          >
            <stop offset="0.3" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="25%"
            fy="25%"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>

          <radialGradient
            id="paint2_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="50%"
            fy="50%"
          >
            <stop offset="0.3" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint3_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="50%"
            fy="50%"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>

          <radialGradient
            id="paint4_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="50%"
            fy="50%"
          >
            <stop offset="0.3" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint5_radial_1:7"
            cx="50%"
            cy="0%"
            r="100%"
            fx="50%"
            fy="50%"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </LogoMAPStyles>
  )
}

LogoMAPAnimate.propTypes = {}

export default LogoMAPAnimate
