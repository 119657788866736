import * as React from "react"

import PublicLayout from "components/layouts/PublicLayout"
import Seo from "components/seo"
import Home from "containers/Home"
import MouseContextProvider from "components/MouseContext"

const IndexPage = () => (
  <PublicLayout>
    <Seo title="Home" />
    <MouseContextProvider>
      <Home />
    </MouseContextProvider>
  </PublicLayout>
)

export default IndexPage
