import React, { useContext, useEffect } from "react"
import useMousePosition from "hooks/useMousePosition"
import { MouseContext } from "../MouseContext"
import { DotRingStyles } from "./styles"

function isDescendant(parent, child) {
  var node = child.parentNode;
  while (node != null) {
      if (node === parent) {
          return true;
      }
      node = node.parentNode;
  }
  return false;
}


const DotRing = () => {
  const { cursorType } = useContext(MouseContext)

  const { x, y } = useMousePosition()

  useEffect(() => {
    const ring = document.querySelector(".ring")
    const dot = document.querySelector(".dot")

    const mouseDownHandler = event => {
      // ring.style.transform = "translate(-50%, -50%) scale(1.4)";
      ring.style.animation = undefined;
      dot.style.animation = undefined;
    }

    const mouseUpHandler = (e) => {
      const parent = document.getElementsByClassName('animation-target')[0];

      if (e.target.classList.contains('animation-target') || isDescendant(parent, e.target)) {
        ring.style.animation = 'ringMouseUp .5s ease-out';
        dot.style.animation = 'dotMouseUp 0.5s ease-out';
      }

      // ring.style.transform = "translate(-50%, -50%) scale(3)"
      // ring.style.opacity = 0;

      // dot.style.transform = "translate(-50%, -50%) scale(0.5)";
      // dot.style.opacity = 0;
    }

    document.addEventListener("mousedown", mouseDownHandler);
    document.addEventListener("mouseup", mouseUpHandler);

    return () => {
      document.removeEventListener("mousedown", mouseDownHandler)
      document.removeEventListener("mouseup", mouseUpHandler)
    }
  }, [])

  return (
    <DotRingStyles>
      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={"ring " + cursorType}
      ></div>
      <div
        className={"dot " + cursorType}
        style={{ left: `${x}px`, top: `${y}px` }}
      ></div>
    </DotRingStyles>
  )
}

export default DotRing
