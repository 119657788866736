import styled from "styled-components"

const LogoMAPStyles = styled.div`
  #MBase {
    animation: MBaseAnimate 2s linear 0s infinite alternate;
  }

  #PBase {
    animation: PBaseAnimate 2s linear 0s infinite alternate;
  }
  @keyframes MBaseAnimate {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-10%);
    }
  }

  @keyframes PBaseAnimate {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(10%);
    }
  }
`;

export default LogoMAPStyles;
